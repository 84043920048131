<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">病害分布图信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="数据密级" prop="sjmj">
                    <el-select
                        v-model="formdata.saveJson.sjmj"
                        placeholder="请选择数据密级"
                        :disabled="!isEdit"
                        @change="changeSJMJ"
                    >
                        <el-option label="公开" value="公开"></el-option>
                        <el-option label="不公开" value="不公开"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-show="formdata.saveJson.sjmj === '公开'"
                    label="发布范围"
                    prop="fbfw"
                >
                    <el-select
                        v-model="formdata.saveJson.fbfw"
                        placeholder="请选择发布范围"
                        :disabled="!isEdit"
                    >
                        <el-option
                            label="互联网公开"
                            value="互联网公开"
                        ></el-option>
                        <el-option
                            label="行业内部公开"
                            value="行业内部公开"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="年份" prop="nf">
                    <el-date-picker
                        v-model="formdata.saveJson.nf"
                        type="year"
                        value-format="yyyy"
                        :disabled="!isEdit"
                        placeholder="请选择年份"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="地图URL" prop="mapid">
                    <el-select
                        v-model="formdata.saveJson.mapid"
                        :disabled="!isEdit"
                        placeholder="请选择URL"
                    >
                        <el-option
                            v-for="{ Id, Url } in mapList"
                            :key="Id"
                            :label="Url"
                            :value="Id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="资料名称" prop="zlmc">
                    <el-input
                        v-model="formdata.saveJson.zlmc"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <basic-upload
                    v-if="formdata.saveJson.sjmj === '公开'"
                    ref="uploadFile"
                    :isEdit="isEdit"
                    :itemId="itemId"
                    :metaData.sync="formdata.metaJson"
                    :basicType="basicType"
                >
                </basic-upload>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "disease_distribution_diagram",
    components: {},
    mixins: [infoMixin],
    data() {
        return {
            snButton: snButton,
            rules: {
                sjmj: [
                    {
                        required: true,
                        message: "请选择数据密级",
                        trigger: "blur",
                    },
                ],
                fbfw: [
                    {
                        required: true,
                        message: "请选择发布范围",
                        trigger: "blur",
                    },
                ],
                nf: [
                    {
                        required: true,
                        message: "请选择年份",
                        trigger: "change",
                    },
                ],
                zlmc: [
                    {
                        required: true,
                        message: "请填写资料名称",
                        trigger: "blur",
                    },
                ],
            },
            formdata: {
                saveJson: {
                    sjmj: "公开",
                    fbfw: "行业内部公开",
                    shzt: 1,
                    nf: null,
                    zlmc: "",
                    mapid: null,
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "11",
                userName: "",
                userId: "",
            },
            mc: "",
            MapitemCode: "11",
        };
    },
    computed: {},
    mounted() {
        this.getYcsygnMapList();
    },
    methods: {},
};
</script>
<style lang="scss" scoped></style>
